import axios from "axios";
import React, { useEffect, useState } from "react";
import AdminDialogueBox from "./AdminDialogueBox";
import AdminNavListItem from "./AdminNavListItem";
import AddNewNavItemDialogueBox from "./AddNewNavItemDialogueBox";
import protectedAxiosInstance from "./helpers/api";
import AdminSelectComponent from "./AdminSelectComponent";


interface props {
  editModeTab: string;
  setEditMode: (value: string) => void;
  cachedNavList: any;
  cachedHorizontalNavList: any;
}

const AdminNavList: React.FC<props> = ({ editModeTab, setEditMode, cachedNavList, cachedHorizontalNavList }) => {
  const [navList, setNavList] = useState<any>([]);
  const [navListTableView, setNavListTableView] = useState<any>([]);
  const [horizontalNavList, setHorizontalNavList] = useState<any>();
  const [editableHorizontalNavList, setEditableHorizontalNavList] = useState<any>();
  const [dataList, setDataList] = useState<any>({});
  const [addNewTab, setAddNewTab] = useState<boolean>(false);
  const [newParentTabText, setNewParentTabText] = useState<any>("");
  const [tableView, setTableView] = useState<any>("");
  const [intitialDataList, setInitialDataList] = useState<any>({});
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showDeleteParentTab, setShowDeleteParentTab] = useState<boolean>(false);
  const [selectedParentTab, setSelectedParentTab] = useState<any>("");
  const [inEditMode, setInEditMode] = useState<any>([]);
  const [showAddToTop, setShowAddToTop] = useState<boolean>(false);
  const [newParentTileId, setNewParentTileId] = useState<any>("");
  const [secondSelectValue, setSecondSelectValue] = useState<string>("select");  
  const [secondLevelData, setSecondLevelData] = useState<any>([]);
  const [thirdLevelData, setThirdLevelData] = useState<any>([]);
  const [isFolderAdditionAllowed, setisFolderAdditionAllowed] = useState<boolean>(true);
  const [isReportAdditionAllowed, setIsReportAdditionAllowed] = useState<boolean>(false);
  const [isConfigValid, setIsConfigValid] = useState<boolean>(false);
  
  // Edit changes validation
  const  validateConfig = () => {
    let flag = false;
    if (selectedParentTab === "") {          // top level check
      navListTableView.forEach((item: any, index: number) => {
        if (item.tileText === "") {
          flag = true;
        }
      });
    }
    else if (selectedParentTab !== "") {     // second and below level check
      dataList[tableView].subPages.forEach((item: any, index: number) => {
        if (item.tileId.includes("new")){
        }
        if (item.tileText === "") {
          flag = true;
        }
      });
    }
    return flag;
  }

  const shiftElement = (arr: any[], index: number, direction: string) => {
    if (index < 0 || index >= arr.length) {
      return arr;  // Invalid index, return original array  
    }
  
    if ((direction === 'up' && index === 0) || 
        (direction === 'down' && index === arr.length - 1)) {
      return arr;  // Can't shift further in this direction, return original array
    }

    let shiftBy = 1;
    if (direction === 'up' && arr[index - 1].tileId === "OCPLanding" && 0 <= index - 2) {
      shiftBy = 2;
    } else if (direction === 'down' && arr[index + 1].tileId === "OCPLanding" && arr.length > index + 2) {
      shiftBy = 2;
    }
    
    const newIndex = (direction === 'down') ? (index + shiftBy) : (index - shiftBy);
    [arr[index], arr[newIndex]] = [arr[newIndex], arr[index]];
    return arr;
  }

  const reOrder = (index: number, direction: string) => {
    if (selectedParentTab === "" && tableView === "") {
      const tmpNavListTableView = [ ...navListTableView ];
      setNavListTableView(shiftElement(tmpNavListTableView, index, direction));
    }else{
      const tmpDataList = { ...dataList };
      tmpDataList[tableView].subPages = shiftElement(tmpDataList[tableView].subPages, index, direction);
      setDataList(tmpDataList);
    }
  }

  const handleChangeTileText = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    tileID: string
  ) => {
    const tmpDataList = { ...dataList };
    const tmpNavListTableView = [ ...navListTableView ];

    if (selectedParentTab === "" && tableView === "" && secondSelectValue === "select") {
      tmpDataList[tileID].tileText = e.target.value;
      tmpNavListTableView[index].tileText = e.target.value;
    }else{
      tmpDataList[tableView].subPages[index].tileText = e.target.value;
    }
    setDataList(tmpDataList);
    setNavListTableView(tmpNavListTableView)
  };

  const handleDelete = (index: number, isFolder: boolean) => {
    
    // delete top level section and early return
    if (isFolder && tableView === "") {
      handleDeleteSection(index)
      return
    }

    const tmpDataList = JSON.parse(JSON.stringify(dataList));
    const tileId = tmpDataList[tableView].subPages[index].tileId;
    tmpDataList[tableView].subPages.splice(index, 1);
    if (!isFolder) {
      handleDeleteReport(index);
    } else {
      handleDeleteFolder(index);
    }
    setDataList(tmpDataList);
    setInEditMode(inEditMode.filter((item: any) => item !== tileId));
    if (index === 0 && tmpDataList[tableView].subPages.length === 0) {
      setShowDialog(true);
    }
  };

  const handleDeleteReport = (index: number) => {
    const updatedHorizontalNavList = { ...editableHorizontalNavList };
    delete updatedHorizontalNavList[
      dataList[tableView].subPages[index].tileId
    ];
    setEditableHorizontalNavList(updatedHorizontalNavList);
  };

  const handleDeleteFolder = (index: number) => {
    const tmpDataList = JSON.parse(JSON.stringify(dataList));
    tmpDataList[tableView].subPages[index].subPages.forEach(
      (item: any) => {
        const updatedHorizontalNavList = { ...editableHorizontalNavList };
        delete updatedHorizontalNavList[item.tileId];
        setEditableHorizontalNavList(updatedHorizontalNavList);
      }
    );
  };

  const handleDeleteSection = (index: number) => {
    let tmpNavListTableView = [ ...navListTableView ];
    tmpNavListTableView.splice(index, 1)
    setNavListTableView(tmpNavListTableView);
  };

  const handleAddNewTab = (index?: number) => {
    const tmpDataList = { ...dataList };
    const newTab = {
      tileId: `-new-${Math.floor(Math.random() * 1000)}`,
      tileText: "",
      hasSubPages: false,
      subPages: [],
    };
    if (index === undefined) {
      tmpDataList[tableView].subPages.unshift(newTab);
    } else {
      tmpDataList[tableView].subPages.splice(index + 1, 0, newTab);
    }
    setDataList(tmpDataList);
  };

  const handleAddNewFolder = (index?: number) => {
    const tmpDataList = { ...dataList };
    const tmpNavListTableView = [ ...navListTableView ]
    const newTab = {
      tileId: `-new-${Math.floor(Math.random() * 1000)}`,
      tileText: "",
      hasSubPages: true,
      subPages: [],
      type: selectedParentTab ? "Subsection" : "Section"
    };
    if (tableView !== "") {
      if (index === undefined) {
        tmpDataList[tableView].subPages.unshift(newTab);
      } else {
        tmpDataList[tableView].subPages.splice(index + 1, 0, newTab);
      }
    } else if (tableView === "") {
      if (index === undefined) {
        tmpDataList[newTab.tileId] = newTab;
        tmpNavListTableView.unshift(newTab);
      } else {
        tmpDataList[newTab.tileId] = newTab;
        tmpNavListTableView.splice(index + 1, 0, newTab);
      }
    }
    setDataList(tmpDataList);
    setNavListTableView(tmpNavListTableView)
  };

  const handleAddNewParentTab = () => {
    //add new parent tab to dataList
    const tmpDataList = { ...dataList };
    const newParentTab = {
      tileId: createTileId(newParentTabText),
      tileText: newParentTabText,
      hasSubPages: true,
      subPages: [],
    };
    tmpDataList[newParentTab.tileId] = newParentTab;
    setDataList(tmpDataList);
  };

  const handleCancel = () => {
    const tmpHorizontalList = JSON.parse(JSON.stringify(horizontalNavList));
    const tmpDataList = JSON.parse(JSON.stringify(intitialDataList));
    const tmpNavList = JSON.parse(JSON.stringify(navList));
    setDataList(tmpDataList);
    setEditableHorizontalNavList(tmpHorizontalList);
    setNavListTableView(tmpNavList)
    setInEditMode([]);
    setEditMode("");
  };

  const handleCancelNewParentTab = () => {
    setAddNewTab(false);
    setNewParentTabText("");
  };

  const createTileId = (tileText: string) => {
    //create unique tileId from tileText
    const text = tileText.replace(/\s/g, "");
    //take first 3 characters and last 3 characters
    const tileId =
      text.substring(0, 3) +
      text.substring(text.length - 3) +
      Math.floor(Math.random() * 1000);
    setNewParentTileId(tileId);
    return tileId;
  };

  const updateNavList = async (navList: any) => {
    try {
      await protectedAxiosInstance.post(
        "/api/service/UpdateNavigationConfiguration",
        {
          Type: "NAV_TILE_LIST",
          Data: JSON.stringify(JSON.stringify(navList)),
        },
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      console.error("Error in updating horizontalTileList", error);
    }
  };

  const updateHorizontalNavList = async (tmpHorizontalList: any) => {
    try {
      await protectedAxiosInstance.post(
        "/api/service/UpdateNavigationConfiguration",
        {
          Type: "HORIZONTAL_TILE_LIST",
          Data: JSON.stringify(JSON.stringify(tmpHorizontalList)),
        },
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      console.error("Error in updating horizontalTileList", error);
    }
  };

  const handleDeleteParentTab = async () => {
    //selectedParentTab is delted from navList
    const tmpDataList = { ...dataList };
    delete tmpDataList[selectedParentTab];

    const tmpNavList = JSON.parse(JSON.stringify(navList));
    tmpNavList.forEach((item: any) => {
      if (item?.tileId === selectedParentTab) {
        const idx = tmpNavList.indexOf(item);
        tmpNavList.splice(tmpNavList.indexOf(item), 1);

        navList[idx].subPages.forEach((subpage: any) => {
          if (subpage.hasSubPages) {
            subpage.subPages.forEach((report: any) => {
              const updatedHorizontalNavList = { ...editableHorizontalNavList };
              delete updatedHorizontalNavList[report.tileId];
              updateHorizontalNavList(updatedHorizontalNavList);
            });
          } else {
            const updatedHorizontalNavList = { ...editableHorizontalNavList };
            delete updatedHorizontalNavList[subpage.tileId];
            updateHorizontalNavList(updatedHorizontalNavList);
          }
        });
      } else {
        item?.subPages.forEach((subpage: any) => {
          if (  subpage.tileId === selectedParentTab) {
            item.subPages.splice(item.subPages.indexOf(subpage), 1);
            subpage.subPages.forEach((report: any) => {
              const updatedHorizontalNavList = { ...editableHorizontalNavList };
              delete updatedHorizontalNavList[report.tileId];
              updateHorizontalNavList(updatedHorizontalNavList);
            });
          }
        });
      }
    });

    updateNavList(tmpNavList);
    setSelectedParentTab("");
  };

  const handleSave = async () => {
    //check if new tab is added
    const tmpDataList = { ...dataList };
    const tmpNavList = [ ...navListTableView ];
    if ( tableView !== "") {
      tmpDataList[tableView]?.subPages.forEach((item: any) => {
        if (item.tileId.includes("-new-")) {
          item.tileId = createTileId(item.tileText);
        }
      });

      //find selectedParentTab in navList and update it
      tmpNavList.forEach((item: any) => {
        if (item != null) {
          if (item.tileId === tableView) {
            item.subPages = tmpDataList[tableView].subPages;
          } else {
            item.subPages.forEach((subpage: any) => {
              if (subpage.tileId === tableView) {
                subpage.subPages = tmpDataList[tableView].subPages;
              }
            });
          }
        }
      });

      updateHorizontalNavList(editableHorizontalNavList);
    } else {
      //new parent tab is added or modified
      tmpNavList.forEach((item: any) => {
        if (item.tileId.includes("-new-")) {
          item.tileId = createTileId(item.tileText);
        }
      });
    }
    
    updateNavList(tmpNavList);
  };

  const setLevelwiseTileData = (navList: any) => {
    let tempSecondLevelData: any = {},
      tempThirdLevelData: any = {};

    navList.forEach((item: any, index: number) => {
      // if(index === 0) return
      if (index > 0) {
        if (item.hasSubPages) {
          // map subPages to the tileId
          tempSecondLevelData[item.tileId] = item.subPages;
          // map third level data to the subPages
          item.subPages.forEach((subPage: any) => {
            if (subPage.hasSubPages) {
              tempThirdLevelData[subPage.tileId] = subPage.subPages;
            } else {
              tempThirdLevelData[subPage.tileId] = [];
            }
          });
        } else {
          tempSecondLevelData[item.tileId] = [];
        }
      }
    });
    setSecondLevelData(tempSecondLevelData);
    setThirdLevelData(tempThirdLevelData);
  };

  const parseNavList = async () => {
    try {
      const tmpnavList = JSON.parse(
        JSON.parse(cachedNavList)
      );
      setLevelwiseTileData(tmpnavList);
      setNavList(JSON.parse(JSON.parse(cachedNavList)));
      tmpnavList.forEach((item: any) => {
          item["type"] = "Section";
      })
      setNavListTableView(tmpnavList);
      // traverse tmpnavList and get keys for which hasSubpages is true
      let tmpDataList: any = {};
      tmpnavList.forEach((item: any) => {
        if (item != null && item.hasSubPages) {
          item["type"] = "Section";
          tmpDataList[item.tileId] = item;
          item.subPages.length > 0 &&
            item.subPages.forEach((subpage: any) => {
              if (subpage.hasSubPages) {
                subpage["type"] = "Subsection";
                tmpDataList[subpage.tileId] = subpage;
              }
            });
        }
      });
      let tmpDataList2 = JSON.parse(JSON.stringify(tmpDataList));
      setInitialDataList(tmpDataList2);
      setDataList(tmpDataList);
    } catch (error) {
      console.error("Error", error);
    }

    try {
      const tmpHorizontalNavList1 = JSON.parse(
        JSON.parse(cachedHorizontalNavList)
      );
      const tmpHorizontalNavList2 = JSON.parse(
        JSON.parse(cachedHorizontalNavList)
      );
      setHorizontalNavList(tmpHorizontalNavList1);
      setEditableHorizontalNavList(tmpHorizontalNavList2);
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    parseNavList();
  }, []);

  useEffect(() => {
    setIsConfigValid(()=>{return validateConfig()});
  },[dataList, navListTableView])
  
  return (
    <div className="containerList">
      {dataList && (
        <div className="NavBarDropdowns" style={{display: "flex", alignItems: "flex-end", flexWrap: "wrap"}}>
            {navList && (
              <div className="selectTabComponent">
                <div className="selectTabText">Select Section</div>
                <AdminSelectComponent
                  onChangeEffect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setTableView(e.target.value);
                    setSelectedParentTab(()=>e.target.value);
                    setSecondSelectValue("select");
                    setEditableHorizontalNavList(
                      JSON.parse(JSON.stringify(horizontalNavList))
                    );
                    setIsReportAdditionAllowed(e.target.value ? true : false);
                  }}
                  title="Select Section"
                  options={navList}
                  selectedValue={selectedParentTab}
                  isParentPresent={true}
                  parentName="Sections"
                  parentId="" // "" null signifying no selection
                />
              </div>
            )}

            {tableView !== "" && secondLevelData[selectedParentTab]?.length > 0 && (
              <div className="selectTabComponent childSelectTabComponent">
                <div className="selectTabText">Select Subsection</div>
                <AdminSelectComponent
                  onChangeEffect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setTableView(e.target.value);
                    setSecondSelectValue(e.target.value);
                    setIsReportAdditionAllowed(true)
                    setisFolderAdditionAllowed(e.target.value === selectedParentTab ? true : false);
                  }}
                  isParentPresent={true}
                  parentName="Subsection Parent"
                  parentId={selectedParentTab}
                  title="Select Report/Subsection"
                  options={secondLevelData[selectedParentTab]}
                  selectedValue={secondSelectValue}
                  ignoreSubpages={true}
                />
              </div>
            )}
        </div>
      )}

      {addNewTab && (
        <div className="addNewParentTabContainer">
          <input
            className="inputNewParentStyle"
            value={newParentTabText}
            onChange={(e) => setNewParentTabText(e.target.value)}
            type="text"
            placeholder="Enter Section Name"
          />
          <button
            disabled={newParentTabText.length === 0 || newParentTabText === " "}
            style={{
              backgroundColor: `${
                newParentTabText.length !== 0 ? "#0078d4" : "#f5f3f3"
              }`,
              color: `${newParentTabText.length !== 0 ? "White" : "Black"}`,
            }}
            onClick={() => {
              handleAddNewParentTab();
              setShowDialog(true);
            }}
          >
            Add
          </button>
          <button
            onClick={() => {
              handleCancelNewParentTab();
            }}
          >
            Cancel
          </button>
        </div>
      )}

{/*
if selectedParentTab is null
  if navList length is greater than 0 then show the table
  else show the add new section button
*/}

      {(navListTableView && !selectedParentTab) &&
        (navListTableView.length > 0 ? (
          <div className="tableContainer">
            <div className="listContainer">
              <table className="navListTable">
                <thead>
                  <tr
                    style={{ position: "sticky", top: "0" }}
                    className="navListTableHeader"
                  >
                    <th className="navListTableHeaderSrNo">Order</th>
                    <th className="navListTableHeaderTileText">Name</th>
                    <th className="navListTableHeaderType">Type</th>
                    <th className="navListTableHeaderActions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {navListTableView.map(
                    (item: any, index: number) => {
                      // return only if the tileId is not OCPLanding
                      if (item.tileId !== "OCPLanding") {
                        return (
                          <AdminNavListItem
                            key={index}
                            item={item}
                            index={index}
                            parentTab={selectedParentTab}
                            inEditMode={inEditMode}
                            setEditModeTab={(value: string) => setEditMode(value)}
                            setEditMode={(value: any) => setInEditMode(value)}
                            handleDelete={handleDelete}
                            handleAddNewTab={handleAddNewTab}
                            handleAddNewFolder={handleAddNewFolder}
                            handleChangeTileText={handleChangeTileText}
                            isFolderAdditionAllowed={isFolderAdditionAllowed}
                            isReportAdditionAllowed={false}
                            reOrder={reOrder}
                          />
                        );
                      }
                    }
                  )}
                </tbody>
              </table>
            </div>
            <button
              onClick={() => {
                setEditMode("navList");
                setShowAddToTop(true);
              }}
            >
              Add new item to top
            </button>
            <div className="buttonContainer">
              <button
                onClick={() => {
                  setShowDialog(true);
                }}
                disabled={
                  isConfigValid ||
                  editModeTab === "horizontalList" ||
                  editModeTab === ""
                }
                style={{
                  backgroundColor: `${
                    isConfigValid ||
                    editModeTab === "horizontalList" ||
                    editModeTab === ""
                      ? "#f5f3f3"
                      : "#0078d4"
                  }`,
                  color: `${
                    isConfigValid ||
                    editModeTab === "horizontalList" ||
                    editModeTab === ""
                      ? "Black"
                      : "White"
                  }`,
                }}
              >
                Save
              </button>
              <button onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "50px",
              height: "80px",
            }}
          >
            <div
              className="addNewContainer"
              onClick={() => {setEditMode("NavList");handleAddNewTab(-1)}}
            >
              <i
                className="ms-Icon ms-Icon--Add addIcon"
                aria-hidden="true"
              ></i>
              <span className="addText">Add new Report</span>
            </div>
            <div
              className="addNewContainer"
              onClick={() => {setEditMode("NavList");handleAddNewFolder(-1)}}
            >
              <i
                className="ms-Icon ms-Icon--Add addIcon"
                aria-hidden="true"
              ></i>
              <span className="addText">Add new Subsection</span>
            </div>
          </div>
        ))}


{/*
else if selectedParentTab is not null and 
  if subPages length is greater than 0 then show the table 
  else show the add new report and add new subsection buttons
*/}
      {selectedParentTab &&
        (dataList[selectedParentTab].subPages.length > 0 ? (
          <div className="tableContainer">
            <div className="listContainer">
              <table className="navListTable">
                <thead>
                  <tr
                    style={{ position: "sticky", top: "0" }}
                    className="navListTableHeader"
                  >
                    <th className="navListTableHeaderSrNo">Order</th>
                    <th className="navListTableHeaderTileText">Name</th>
                    <th className="navListTableHeaderType">Type</th>
                    <th className="navListTableHeaderActions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {dataList[tableView].subPages.map(
                    (item: any, index: number) => {
                      return (
                        <AdminNavListItem
                          key={index}
                          item={item}
                          index={index}
                          parentTab={selectedParentTab}
                          inEditMode={inEditMode}
                          setEditModeTab={(value: string) => setEditMode(value)}
                          setEditMode={(value: any) => setInEditMode(value)}
                          handleDelete={handleDelete}
                          handleAddNewTab={handleAddNewTab}
                          handleAddNewFolder={handleAddNewFolder}
                          handleChangeTileText={handleChangeTileText}
                          isFolderAdditionAllowed={isFolderAdditionAllowed}
                          reOrder={reOrder}
                        />
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
            <button
              onClick={() => {
                setEditMode("navList");
                setShowAddToTop(true);
              }}
            >
              Add new item to top
            </button>
            <div className="buttonContainer">
              <button
                onClick={() => {
                  setShowDialog(true);
                }}
                disabled={
                  isConfigValid ||
                  editModeTab === "horizontalList" ||
                  editModeTab === ""
                }
                style={{
                  backgroundColor: `${
                    isConfigValid ||
                    editModeTab === "horizontalList" ||
                    editModeTab === ""
                      ? "#f5f3f3"
                      : "#0078d4"
                  }`,
                  color: `${
                    isConfigValid ||
                    editModeTab === "horizontalList" ||
                    editModeTab === ""
                      ? "Black"
                      : "White"
                  }`,
                }}
              >
                Save
              </button>
              <button onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "50px",
              height: "80px",
            }}
          >
            <div
              className="addNewContainer"
              onClick={() => {setEditMode("NavList");handleAddNewTab(-1)}}
            >
              <i
                className="ms-Icon ms-Icon--Add addIcon"
                aria-hidden="true"
              ></i>
              <span className="addText">Add new Report</span>
            </div>
            <div
              className="addNewContainer"
              onClick={() => {setEditMode("NavList");handleAddNewFolder(-1)}}
            >
              <i
                className="ms-Icon ms-Icon--Add addIcon"
                aria-hidden="true"
              ></i>
              <span className="addText">Add new Subsection</span>
            </div>
          </div>
        ))}
      {showDialog && (
        <AdminDialogueBox
          message="Are you sure you want to save the changes?"
          buttonText="Save"
          saveChanges={handleSave}
          setShowDialogue={(value: boolean) => setShowDialog(value)}
          onCancel={handleCancel}
        />
      )}
      {showDeleteParentTab && (
        <AdminDialogueBox
          message="Are you sure you want to delete the Section? This action cannot be undone."
          buttonText="Delete"
          saveChanges={handleDeleteParentTab}
          setShowDialogue={(value: boolean) => setShowDeleteParentTab(value)}
          onCancel={handleCancel}
        />
      )}
      {showAddToTop && (
        <AddNewNavItemDialogueBox
          addNewReport={() => handleAddNewTab()}
          addNewFolder={() => handleAddNewFolder()}
          setShowDialogue={(value) => setShowAddToTop(value)}
          message="This new item will be added to the top of the selected Section/Subsection. Select the type of item to add:"
          isReportAdditionAllowed={isReportAdditionAllowed}
          additionType={selectedParentTab ? "Subsection" : "Section"}
          isFolderAdditionAllowed={isFolderAdditionAllowed}
        />
      )}
    </div>
  );
};

export default AdminNavList;
